.g-align {
	&_left {
		text-align: left;
	}

	&_right {
		text-align: right;
	}

	&_center {
		text-align: center;
	}
}
