.b-cardStep {
	color: get-color(white);
	background-color: get-color(blue--darker);
	border-radius: 15px;

	@include breakpoint(xs, lg) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 16px 10px;
	}

	@include breakpoint(lg) {
		padding: 30px;
	}

	&-title {
		font-weight: 700;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			align-self: center;
			font-size: 16px;
			text-align: center;
		}

		@include breakpoint(lg) {
			font-size: 22px;
			margin-bottom: 16px;
		}
	}

	&-text {
		font-weight: 300;

		@include breakpoint(xs, lg) {
			display: none;
		}
	}
}
