.b-pictureList {
	@include breakpoint(md) {
		@include row();
		margin-bottom: -32px;
	}

	&:not(:first-child) {
		margin-top: 32px;
	}

	&:not(:first-child) {
		@include breakpoint(xs, md) {
			margin-bottom: 32px;
		}

		@include breakpoint(md) {
			margin-bottom: 0px;
		}
	}

	&-item {
		@include breakpoint(md) {
			@include column(4);
			margin-bottom: 32px;
		}

		&:not(:last-child) {
			@include breakpoint(xs, md) {
				margin-bottom: 15px;
			}
		}
	}

	@media print {
		display: none;
	}
}
