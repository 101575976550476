.b-tabs {
	margin-bottom: 1rem;

	&-list {
		@include reset-list;
		display: flex;
	}
	&-tab {
		@include button(get-color(light));
		display: block;
		border: 1px solid transparent;
		border-bottom-width: 0;

		&.m-active {
			margin-top: 1px;
			margin-bottom: -1px;
			border-color: get-color(darkgray);
		}
	}
	&-panelList {
		padding: .8em;
		border: 1px solid get-color(darkgray);
	}
	&-panel {
		display: none;

		&.m-active {
			display: block;
		}
	}
}
