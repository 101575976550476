.b-icon {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	transition: none;

	&::before,
	&::after {
		content: '';
		display: block;
		transition: .3s linear opacity;
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	@each $sprite-name, $sprite in $sprites {
		&_#{$sprite-name} {
			@include sprite($sprite-name, true);
		}
	}

	.b-btn > & + span,
	.b-btn > span + &,
	.b-linkMore > & + span,
	.b-linkMore > span + & {
		margin-left: 16px;
	}

	.b-btn > &,
	.b-linkMore > & {
		flex-shrink: 0;
	}

	// @each $icon in (headPhone) {
	// 	&_#{$icon} {
	// 		@include icon('#{$icon}-blueDark', '#{$icon}-white');
	// 	}
	// }
}

a:hover .b-icon,
button:hover .b-icon {
	&::before {
		opacity: 0;
	}

	&::after {
		opacity: 1;
	}
}
