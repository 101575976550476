$sprites: () !default;
$spritegroups: () !default;

@mixin sprite-group($sprite) {
	width: map-get($sprite, 'width') * 1px;
	height: map-get($sprite, 'height') * 1px;
	background-image: url('#{map-get($sprite, 'sprite_url')}');
	background-repeat: no-repeat;
	background-size: percentage(map-get($sprite, 'sprite_width_rel')) percentage(map-get($sprite, 'sprite_height_rel'));
	background-clip: content-box;
	background-origin: content-box;
}

@each $sprite-group, $sprite in $spritegroups {
	%sprite_#{$sprite-group} {
		@include sprite-group($sprite);
	}
}

@mixin sprite($sprite-name, $extend: false) {
	@if not map-has-key($sprites, $sprite-name) {
		@warn '#{$sprite-name} is not defined in $sprites';
	} @else {
		$sprite: map-get($sprites, $sprite-name);

		@if $extend {
			@extend %sprite_#{map-get($sprite, 'group')};
		} @else {
			@include sprite-group($sprite);
		}

		background-position: percentage(map-get($sprite, 'x_rel')) percentage(map-get($sprite, 'y_rel'));
	}
}

@mixin sprite-size($sprite-name, $scale: 1) {
	$sprite: map-get($sprites, $sprite-name);

	width: map-get($sprite, 'width') * $scale * 1px;
	height: map-get($sprite, 'height') * $scale * 1px;
}
