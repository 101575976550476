.b-media {
	display: flex;
	flex-direction: column;
	background-color: #c9cacf;
	box-sizing: border-box;
	border-radius: 15px;

	@include breakpoint(xs, lg) {
		padding: 28px 15px 15px;
	}

	@include breakpoint(lg) {
		padding: 28px;
	}

	&-title {
		font-weight: 700;
		font-size: 22px;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			margin-bottom: 12px;
		}

		@include breakpoint(lg) {
			margin-bottom: 18px;
			font-size: 22px;
		}
	}

	&-text {
		@include breakpoint(xs, lg) {
			margin-bottom: 16px;
		}

		@include breakpoint(lg) {
			margin-bottom: 28px;
		}
	}

	&-action {
		margin-top: auto;
	}
}
