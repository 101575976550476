.b-tizerInfo {
	background-color: get-color(pink);
	color: get-color(white);
	border-radius: 15px;
	font-size: 18px;
	font-weight: 500;
	text-align: center;

	@include breakpoint(xs, lg) {
		padding: 12px 15px;
	}

	@include breakpoint(lg) {
		padding: 32px 16px;
	}

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	a {
		color: get-color(white);
	}
}
