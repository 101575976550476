.b-controlList {
	@include reset-list;

	&_test {
		@include row();
		margin-bottom: -8px;

		& > * {
			@include column(12);
			margin-bottom: 8px;

			@include breakpoint(lg) {
				@include column-width(3);
			}
		}
	}
}
