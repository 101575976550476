@mixin reset-link($color: get-color(dark)) {
	color: inherit;

	&:hover {
		color: inherit;
	}
}

@mixin reset-list {
	padding: 0;
	margin: 0;
	list-style: none;
}

@mixin link($color: get-color(blue)) {
	color: $color;
	text-decoration: none;
	outline: none;
	transition: color .3s, background .3s;

	&:hover {
		color: darken($color, 15%);
	}
}

@mixin input {
	height: 48px;
	padding: 12px 24px;
	font: inherit;
	// background-color: get-color(gray--light);
	border: 2px solid get-color(pink);

	@include breakpoint(lg) {
		padding-top: 14px;
		padding-bottom: 14px;
	}

	&:disabled {
		background: get-color(lightgray);
	}
}

@mixin select {
	height: 2.125em;
	padding: .25em;
	background-image: url(../images/select-arrow.svg);
	background-repeat: no-repeat;
	background-position: calc(100% - .4em) 50%;
	border-radius: 0;
	appearance: none;
}

@mixin button($color: get-color(black)) {
	display: inline-block;
	padding: 10px 36px;
	font: inherit;
	color: color-yiq($color);
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	background: $color;
	border: 1px solid transparent;
	border-radius: 48px;

	&:hover {
		color: color-yiq(darken($color, 7.5%));
		text-decoration: none;
		background: darken($color, 7.5%);
	}
}

@mixin placholder-grid($first-color: white, $second-color: gray, $size: 20px) {
	background: $first-color;
	background-image: linear-gradient(0deg, $second-color 1px, transparent 0), linear-gradient(90deg, $second-color 1px, transparent 0);
	background-size: $size $size;
}

*,
::after,
::before {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	// Helvetica-Cyrillic, 'Helvetica Neue' need for Safari\IOS 13 rouble font
	font-family: Roboto, Helvetica-Cyrillic, Helvetica, 'Helvetica Neue', Arial, sans-serif;
	line-height: 1.4;
	text-align: left;
	font-size: 16px;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
	color: get-color(black);
	background-color: get-color(gray--lighter);

	&.m-noscroll {
		padding-right: var(--window-scrollbar-width, 0);
		overflow: hidden;
	}
}

h1,
.b-h1 {
	@include breakpoint(xs, lg) {
		margin: 48px 0 24px;
		font-size: 32px;
	}

	@include breakpoint(lg) {
		margin: 56px 0 40px;
		font-size: 48px;
	}
}

h2,
.b-h2 {
	margin: 56px 0 24px;
	font-size: 30px;
}

h3,
.b-h3 {
	@include breakpoint(xs, lg) {
		margin: 56px 0 16px;
		font-size: 20px;
	}

	@include breakpoint(lg) {
		margin: 72px 0 24px;
		font-size: 32px;
	}
}

h4,
.b-h4 {
	@include breakpoint(xs, lg) {
		margin: 48px 0 16px;
		font-size: 18px;
	}

	@include breakpoint(lg) {
		margin: 64px 0 16px;
		font-size: 24px;
	}
}

h5,
.b-h5 {
	@include breakpoint(xs, lg) {
		margin: 40px 0 16px;
		font-size: 16px;
	}

	@include breakpoint(lg) {
		margin: 56px 0 16px;
		font-size: 20px;
	}
}

h6,
.b-h6 {
	font-weight: 700;
	text-transform: uppercase;

	@include breakpoint(xs, lg) {
		margin: 48px 0 16px;
		font-size: 12px;
	}

	@include breakpoint(lg) {
		margin: 48px 0 16px;
		font-size: 13px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.b-h1,
.b-h2,
.b-h3,
.b-h4,
.b-h5,
.b-h6 {
	font-family: RobotoSlab;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h1,
h2,
h3,
h4,
h5,
.b-h1,
.b-h2,
.b-h3,
.b-h4,
.b-h5,
.b-h6 {
	font-weight: 700;
}

p {
	margin-top: 0;
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	@include link;
}

address {
	font-style: inherit;
}

input {
	// For IOS devices
	border-radius: 0;
}

input[type='submit'] {
	// For IOS devices
	appearance: none;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font: inherit;
	text-transform: none;
	outline: none;
	box-shadow: none;
}

img {
	max-width: 100%;
	vertical-align: middle;
}

[hidden],
template {
	display: none !important;
}

table {
	width: 100%;
	max-width: 100%;
	border-spacing: 0;
	border: 1px solid get-color(darkgray);
	border-width: 0 0 1px 1px;
}
thead,
tbody,
tfoot,
tr {
	border: inherit;
}
th,
td {
	padding: .25em .5em;
	border: inherit;
	border-width: 1px 1px 0 0;
}
th {
	background: get-color(lightgray);
}

hr {
	position: relative;
	display: block;
	height: 1px;
	margin: 24px 0;
	font-size: 0;
	line-height: 0;
	background: none;
	border: none;
	border-bottom: 1px solid get-color(blue--dark);
}
