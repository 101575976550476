.b-cardStructure {
	background-color: #c9cacf;

	@include breakpoint(xs, lg) {
		padding: 40px 15px 15px;
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		padding: 40px 32px 16px;
		border-radius: 15px;
	}

	&-title {
		margin-bottom: 28px;
		font-weight: 700;
		font-family: RobotoSlab;
		font-size: 36px;
	}

	&-text {
		margin-bottom: 32px;
	}
}
