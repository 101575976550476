.b-cardCourse {
	box-sizing: border-box;
	color: get-color(white);
	background-color: #799cdb;
	border-radius: 15px;

	@include breakpoint(xs, lg) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 30px 16px;
	}

	@include breakpoint(lg) {
		padding: 24px 14px 20px;
	}

	&_pink {
		background-color: get-color(pink);
	}

	&-title {
		font-weight: 700;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			font-size: 16px;
			text-align: center;
		}

		@include breakpoint(lg) {
			margin-bottom: 14px;
			font-size: 22px;
		}
	}

	&-content {
		@include breakpoint(xs, lg) {
			display: none;
		}

		.b-btn {
			@include breakpoint(lg) {
				padding: 10px 22px;
			}
		}
	}

	&-img {
		@include breakpoint(lg) {
			max-width: 135px;
			border-radius: 50%;
			overflow: hidden;
		}
	}

	&-text {
		@include breakpoint(lg) {
			margin-bottom: 18px;
			color: get-color(black);
		}
	}
}
