.b-startSubscribeBox {
	background-color: #5acde9;

	&:not(:first-child) {
		@include breakpoint(xs, lg) {
			margin-top: 48px;
		}

		@include breakpoint(lg) {
			margin-top: 56px;
		}
	}

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	@include breakpoint(xs, lg) {
		padding: 0 0 15px;
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		max-width: column-width(10, 12, 30px);
		margin-left: auto;
		margin-right: auto;
		filter: drop-shadow(0 0 40px rgba(0,0,0,.15));
	}

	&-img {
		text-align: center;

		img {
			@include breakpoint(xs, lg) {
				margin-top: -30px;
			}

			@include breakpoint(lg) {
				margin-top: -50px;
			}
		}
	}

	.b-form {
		max-width: 820px;
		padding-left: 15px;
		padding-right: 15px;
		margin-left: auto;
		margin-right: auto;

		.b-btn {
			font-weight: 700;
			font-family: RobotoSlab;
			color: white;

			@include breakpoint(xs, sm) {
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}

	@media print {
		display: none;
	}
}
