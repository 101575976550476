$grid-columns: 12 !default;
$grid-gutter: 20px !default;

$container-max: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
) !default;
$container-padding: () !default;

@function column-width($size, $columns: $grid-columns, $gutter: $grid-gutter) {
	@return calc(#{percentage($size / $columns)} - #{$gutter} - 0.02px);
}

@mixin container($gutter: $grid-gutter) {
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
	margin-right: auto;
	margin-left: auto;

	@each $breakpoint, $padding in $container-padding {
		@include breakpoint($breakpoint) {
			padding-right: $gutter / 2 + $padding;
			padding-left: $gutter / 2 + $padding;
		}
	}

	@each $breakpoint, $max-width in $container-max {
		@include breakpoint($breakpoint) {
			max-width: $max-width;
		}
	}
}

@mixin container-row($gutter: 0) {
	@include container($gutter);
	@include row($gutter);
}

@mixin row($gutter: $grid-gutter) {
	display: flex;
	flex-wrap: wrap;
	@if $gutter != 0 {
		margin-right: -$gutter / 2;
		margin-left: -$gutter / 2;
	}
}

@mixin column($size: auto, $columns: $grid-columns, $gutter: $grid-gutter) {
	@include column-base($gutter);
	@include column-width($size, $columns, $gutter);
}

@mixin column-base($gutter: $grid-gutter) {
	position: relative;
	min-height: 1px;
	margin-right: $gutter / 2;
	margin-left: $gutter / 2;
}

@mixin column-width($size, $columns: $grid-columns, $gutter: $grid-gutter) {
	@if $size == 'none' {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	} @elseif $size == 'auto' {
		flex: 1 1 0%;
		width: 100%;
		max-width: column-width($columns, $columns, $gutter);
	} @else {
		flex: 0 0 column-width($size, $columns, $gutter);
		width: 100%;
		max-width: column-width($size, $columns, $gutter);
	}
}
