.b-tableScroll {
	overflow: auto;
	max-height: calc(100vh - 200px);
	background:
		linear-gradient(to right, white, transparent) left local,
		linear-gradient(to right, rgba(0, 0, 0, .2), transparent 75%) left scroll,
		linear-gradient(to left, white, transparent) right local,
		linear-gradient(to left, rgba(0, 0, 0, .2), transparent 75%) right scroll;
	background-repeat: no-repeat;
	background-size: 15px 100%;

	thead {
		position: sticky;
		top: 0;
	}
}