.l-section {
	margin: 0 calc(50% - 50vw + 9px);
	margin: 0 calc(50% - 50vw + var(--window-scrollbar-width, 0px) / 2);

	&_overflowXs {
		@include breakpoint(xs, lg) {
			overflow: hidden;
		}
	}

	&_mediaSlider {
		@include breakpoint(xs, lg) {
			padding-top: 24px;
			padding-bottom: 0;
		}

		@include breakpoint(lg) {
			padding-top: 30px;
			padding-bottom: 0;
		}
	}

	&_startBox {
		&:not(:first-child) {
			@include breakpoint(xs, lg) {
				margin-top: 32px;
			}
		}

		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}

	&_pink {
		background-color: get-color(pink);
	}

	&_bgPoster {
		color: get-color(white);
		background-image: linear-gradient(to bottom, rgba(0,0,0,0.80), rgba(0,0,0,0.80)), url('../images/poster-bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		@include breakpoint(xs, lg) {
			padding: 30px 0;
		}

		@include breakpoint(lg) {
			padding: 60px 0;
		}

		> .b-form {
			margin-top: 24px;
		}

		&:not(:first-child) {
			margin-top: 45px;
		}

		&:not(:last-child) {
			margin-bottom: 45px;
		}
	}

	@media print {
		display: none;
	}
}
