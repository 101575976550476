.b-startBox {
	@include breakpoint(lg) {
		@include row();
		margin-top: 64px;
	}

	&-content {
		@include breakpoint(xs, lg) {
			padding: 32px 0;
		}

		@include breakpoint(lg) {
			@include column(7);
			padding: 64px 0;
		}

		@include breakpoint(lg-xlSm) {
			padding-left: 12%;
		}
	}

	&-img {
		@include breakpoint(lg) {
			@include column(5);
		}
	}

	&-title {
		margin-bottom: 28px;
		font-weight: 700;
		font-size: 30px;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			text-align: center;
		}
	}

	&-text {
		margin-bottom: 28px;
	}

	&-img {
		@include breakpoint(xs, lg) {
			display: none;
		}

		img {
			@include breakpoint(lg) {
				margin-top: -56px;
			}
		}
	}
}
