.b-menu {
	@include reset-list;

	@include breakpoint(xs, lg) {
		border-top: 1px solid #c9cacf;
		border-bottom: 1px solid #c9cacf;
	}

	@include breakpoint(lg) {
		@include row();
	}

	&-item {
		position: relative;

		@include breakpoint(xs, lg) {
			padding: 20px 16px;
			font-size: 24px;
		}

		@include breakpoint(lg) {
			padding: 12px 16px;
		}

		&:not(:first-child) {
			@include breakpoint(xs, lg) {
				border-top: 1px solid #c9cacf;
			}
		}
	}

	&-head {
		@include breakpoint(xs, lg) {
			font-size: 18px;
		}

		a {
			position: relative;
			color: #333;
		}
	}

	&-item:hover &-head a {
		&::after {
			@include breakpoint(lg) {
				opacity: 1;
			}
		}
	}

	&-item_dropdown &-head a {
		&::after {
			display: inline-block;
			vertical-align: middle;
			content: '';
			@include sprite('triangle-menu');
			opacity: .5;

			@include breakpoint(xs, lg) {
				margin-left: 8px;
				transition: transform .3s;
				transform: rotate(0deg);
			}

			@include breakpoint(lg) {
				margin-left: 10px;
			}
		}
	}

	&-item_dropdown.m-open &-head a {
		&::after {
			@include breakpoint(xs, lg) {
				opacity: 1;
			}

			@include breakpoint(xs, lg) {
				transform: rotate(-180deg);
			}
		}
	}

	&-body {
		@include reset-list;
		font-size: 16px;

		@include breakpoint(xs, lg) {
			display: none;
			padding-top: 8px;
		}

		@include breakpoint(lg) {
			position: absolute;
			top: calc(100% - 5px);
			left: 0;
			z-index: 1;
			display: inherit !important;
			width: 208px;
			visibility: hidden;
			background: get-color(white);
			border: 1px solid #dbdbdb;
			box-shadow: 0 50px 80px rgba(0, 0, 0, .25);
			opacity: 0;
			transition: opacity .3s;
		}

		// &::before {
		// 	@include breakpoint(lg) {
		// 		position: absolute;
		// 		top: -18px;
		// 		left: 0;
		// 		width: 100%;
		// 		height: 18px;
		// 		content: '';
		// 	}
		// }
	}

	&-item_dropdown:hover &-body {
		@include breakpoint(lg) {
			visibility: visible;
			opacity: 1;
		}
	}

	&-bodyItem {
		font-size: 16px;

		@include breakpoint(xs, lg) {
			margin-top: 16px;
		}

		&:not(:first-child) {

			@include breakpoint(lg) {
				border-top: 1px solid #c9cacf;
			}
		}

		a {
			color: #333;

			@include breakpoint(lg) {
				display: block;
				padding: 12px 16px;
			}


			&:hover {
				@include breakpoint(lg) {
					color: get-color(white);
					background-color: get-color(blue);
				}
			}
		}
	}
}
