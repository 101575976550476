.b-humburger {
	display: block;
	width: 72px;
	height: 24px;
	padding: 24px 18px;
	margin: 0;
	cursor: pointer;
	background: none;
	border: none;
	outline: 0;

	&-container {
		position: relative;
		width: 36px;
		height: 100%;
	}

	&-layer {
		position: relative;
		display: block;
		width: 36px;
		height: 4px;
		background-color: get-color(blue--darker);

		&::before,
		&::after {
			position: absolute;
			left: 0;
			display: block;
			width: inherit;
			height: 3px;
			content: '';
			background: inherit;
			background: get-color(blue--darker);
			transition: top .3s, bottom .3s, transform .3s;
		}

		&::before {
			bottom: 8px;
		}
		&::after {
			top: 8px;
		}
	}

	&.m-active {
		.b-humburger-layer {
			background-color: hsla(0, 0%, 100%, 0);

			&::before {
				bottom: 0;
				transform: rotate(-45deg);
			}

			&::after {
				top: 0;
				transform: rotate(45deg);
			}
		}
	}
}
