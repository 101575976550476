.g-ratio {
	position: relative;
	display: block;
	overflow: hidden;

	&::before {
		display: block;
		width: 100%;
		padding-bottom: 100%;
		content: '';
	}

	& > .b-youtubeVideoPreview,
	& > img,
	& > video,
	& > iframe,
	& > embed,
	& > object {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&_cover > img,
	&_cover > video {
		object-fit: cover;
	}

	&_contain & > img,
	&_contain & > video {
		object-fit: contain;
	}
}
