.b-cardHeroWrap {
	margin-bottom: 30px;

	@include breakpoint(lg) {
		@include row();
	}

	&-poster {
		@include breakpoint(xs, lg) {
			margin-bottom: 30px;
		}

		@include breakpoint(lg) {
			@include column(9);
		}

		@include breakpoint(lg-xlSm) {
			@include column-width(10);
		}
	}

	&-info {
		@include breakpoint(xs, lg) {
			@include row();
		}

		@include breakpoint(lg) {
			@include column(3);
			display: flex;
			flex-direction: column;
		}

		@include breakpoint(lg-xlSm) {
			@include column-width(2);
		}

		.b-cardCourse {
			@include breakpoint(xs, lg) {
				@include column(6);
			}

			@include breakpoint(lg) {
				min-height: calc(50% - 15px);
			}

			&:not(:last-child) {
				@include breakpoint(lg) {
					margin-bottom: 30px;
				}
			}
		}
	}
}
