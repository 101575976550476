.b-foot {
	color: get-color(white);
	background: get-color(blue--dark);
	text-align: center;

	@include breakpoint(xs, lg) {
		padding: 40px 15px;
		margin-top: 50px;
	}

	@include breakpoint(lg) {
		padding: 40px 68px;
		margin: 0 auto;
		margin-top: 60px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		max-width: 1140px;
		width: 100%;
	}

	&-list {
		@include reset-list;
		font-size: 16px;
		font-weight: 300;
		color: #c9cacf;

		&_copy {
			font-size: 14px;
		}

		li:not(:first-child) {
			margin-top: 8px;
		}
	}

	a {
		color: get-color(gray--lighter);
		border-bottom: 1px solid;
	}

	hr {
		border-bottom-color: #c9cacf;

		@include breakpoint(lg) {
			margin: 32px 0;
		}
	}

	@media print {
		display: none;
	}
}
