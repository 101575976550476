.b-cardHero {
	position: relative;
	background-color: #dee2f1;

	@include breakpoint(xs, lg) {
		padding: 0 15px 30px;
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		border-radius: 15px;
	}

	&-content {
		@include breakpoint(lg) {
			position: relative;
			max-width: 44%;
			padding: 130px 50px 88px;
		}
	}

	&-title {
		font-weight: 700;
		font-size: 36px;
		line-height: 1.1;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			text-align: center;
			margin-bottom: 16px;
		}

		@include breakpoint(lg) {
			margin-bottom: 32px;
		}
	}

	&-text {
		@include breakpoint(xs, lg) {
			display: none;
		}

		@include breakpoint(lg) {
			max-width: 308px;
			margin-bottom: 16px;
		}
	}

	&-action {
		@include breakpoint(xs, lg) {
			margin-bottom: 20px;
			text-align: center;
		}

		@include breakpoint(lg) {
			margin-bottom: 48px;
		}
	}

	&-img {
		@include breakpoint(xs, lg) {
			margin-top: -16px;
			margin-bottom: 16px;
			text-align: center;
		}

		@include breakpoint(lg) {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
		}
	}
}
