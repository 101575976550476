.b-videoBox {
	background-color: get-color(blue--darker);
	color: get-color(white);

	@include breakpoint(xs, lg) {
		padding: 20px 15px;
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		max-width: column-width(10, 12, 30px);
		margin-left: auto;
		margin-right: auto;
		padding: 18px 15px;
		border-radius: 15px;
	}

	&:not(:first-child) {
		margin-top: 32px;
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}

	&-row {
		@include breakpoint(lg) {
			@include row();
			align-items: center;
		}

		& > * {
			@include breakpoint(lg) {
				@include column(6);
			}
		}
	}

	&-title {
		font-family: RobotoSlab;
		font-weight: 700;
		font-size: 18px;
	}

	&-content {
		@include breakpoint(xs, lg) {
			margin-bottom: 14px;
		}

		@include breakpoint(lg) {
			padding-left: 10px;
		}
	}

	a {
		color: get-color(white);
		border-bottom: 1px solid;
	}

	@media print {
		display: none;
	}
}
