.b-cardLessonList {
	@include breakpoint(lg) {
		@include row();
		margin-bottom: -30px;
	}

	& > * {
		@include breakpoint(lg) {
			@include column(6);
			min-height: 364px;
			margin-bottom: 30px;
		}

		&:not(:last-child) {
			@include breakpoint(xs, lg) {
				margin-bottom: 30px;
			}
		}
	}
}
