.b-testBox {
	background-color: #c9cacf;

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	@include breakpoint(xs, lg) {
		padding: 32px 15px;
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		padding: 32px;
		border-radius: 15px;
	}

	&-num {
		flex-shrink: 0;
		min-width: 72px;
		margin-right: 14px;
		color: #666666;
		font-weight: 700;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			margin-bottom: 12px;
		}

		span {
			font-size: 24px;
			color: #282828;
		}
	}

	&-head {
		margin-bottom: 20px;

		@include breakpoint(lg) {
			display: flex;
			align-items: center;
		}
	}

	&-progress {
		position: relative;
		flex-grow: 1;
		height: 14px;
		background-color: get-color(white);
		border-radius: 4px;
		overflow: hidden;
	}

	&-line {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: #799cdb;
	}

	&-desc {
		margin-bottom: 24px;
		font-size: 18px;
		font-weight: 500;

		span {
			color: get-color(blue);
		}
	}

	&-body {
		padding-top: 30px;
		padding-bottom: 30px;
		border-top: 1px solid get-color(white);
		border-bottom: 1px solid get-color(white);

		> .b-controlList {
			@include breakpoint(xs, lg) {
				padding-left: 14px;
				padding-right: 14px;
			}

			@include breakpoint(lg) {
				padding-left: 84px;
				padding-right: 84px;
			}
		}
	}

	&-actions {
		display: flex;
		flex-wrap: wrap;
		margin-right: -12px;
		margin-left: -12px;
		justify-content: space-between;
		margin-top: 30px;

		& > .b-btn {
			margin: 0 12px;

			&:last-child {
				margin-left: auto;
			}
		}
	}
}
