.b-cardLesson {
	display: flex;
	flex-direction: column;
	background-color: #dee2f1;
	border-radius: 15px;

	@include breakpoint(xs, lg) {
		padding: 24px 15px 15px;
	}

	@include breakpoint(lg) {
		padding: 24px 24px 32px;
	}

	&-caption {
		padding-left: 28px;
		margin-bottom: 8px;
		font-weight: 700;
		font-size: 16px;
	}

	&-info {
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		@include breakpoint(xs, lg) {
			flex-wrap: wrap;
		}

		> .b-icon {
			flex-shrink: 0;
			margin-right: 4px;
		}

		.b-weekDays {
			@include breakpoint(lg) {
				margin-right: 12px;
			}
		}
	}

	&-time {
		display: flex;
		align-items: center;
		font-weight: 700;

		@include breakpoint(xs, lg) {
			width: 100%;
			margin-top: 6px;
		}

		> .b-icon {
			flex-shrink: 0;
			margin-right: 6px;
		}
	}

	&-title {
		font-size: 22px;
		font-weight: 700;
		font-family: RobotoSlab;
	}

	&-text {
		margin-bottom: 20px;

		ul {
			padding-left: 16px;
			margin: 0;
		}
	}

	&-foot {
		margin-top: auto;

		@include breakpoint(md) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.b-btn {
			padding-left: 24px;
			padding-right: 10px;
			font-size: 18px;
			font-weight: 700;
			font-family: RobotoSlab;
		}
	}

	&-prices {
		@include breakpoint(xs, lg) {
			margin-bottom: 28px;
		}
	}

	&-oldPrice {
		font-size: 18px;
		color: #6d6d6d;
		text-decoration: line-through;
	}

	&-price {
		font-size: 30px;
		font-weight: 700;
		font-family: RobotoSlab;
	}
}
