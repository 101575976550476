$aspect-ratios: (
	(1:1),
	(16:9),
	(34:23),
	(42:36),
	(52:23),
	(75:47),
) !default;

@each $ratio in $aspect-ratios {
	@each $antecedent, $consequent in $ratio {
		@if (type-of($antecedent) != number) {
			@error '`#{$antecedent}` needs to be a number.';
		}

		@if (type-of($consequent) != number) {
			@error '`#{$consequent}` needs to be a number.';
		}

		&.g-ratio_#{$antecedent}\:#{$consequent}::before {
			padding-bottom: ($consequent/$antecedent) * 100%;
		}
	}
}
