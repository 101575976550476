.b-accordionTest {
	@include reset-list;

	&-item {
		display: flex;

		@include breakpoint(xs, lg) {
			padding: 15px 4px;
		}

		@include breakpoint(lg) {
			padding: 15px;
			margin: 0 16px;
		}

		&:nth-child(2n) {
			background-color: #dee2f1;
		}
	}

	&-header {
		position: relative;
		cursor: pointer;
		flex-shrink: 0;
		font-weight: 500;
		font-size: 18px;

		@include breakpoint(xs, lg) {
			min-width: 72px;
			padding-left: 24px;
		}

		@include breakpoint(lg) {
			min-width: 54px;
			pointer-events: none;
		}

		&::before {
			@include breakpoint(xs, lg) {
				content: "";
				display: inline-block;
				position: absolute;
				top: 5px;
				left: 4px;
				transition: transform .3s cubic-bezier(.5,0,.3,1);
				@include sprite('accordion-arrow');
			}
		}
	}

	&-item.m-open &-header::before {
		transform: rotate(180deg);
	}

	&-body {
		flex-grow: 1;

		@include breakpoint(xs, lg) {
			display: none;
		}

		@include breakpoint(lg) {
			display: block !important;
		}
	}

	&-content {

		@include breakpoint(xs, lg) {
			opacity: 0;
			transition: opacity .45s cubic-bezier(.5,0,.3,1);
		}
	}

	&-item.m-open &-content {
		@include breakpoint(xs, lg) {
			opacity: 1;
			transition-delay: .2s;
		}
	}
}
