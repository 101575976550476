.b-control {
	position: relative;
	display: inline-flex;
	align-items: start;

	&_file {
		display: flex;
	}

	&-input {
		position: absolute;
		clip: rect(0, 0, 0, 0);
	}
	&-indicator {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-right: 10px;
		border: 1px solid get-color(pink);
		background: get-color(white);

		&::before {
			display: block;
			visibility: hidden;
			opacity: 0;
			width: 14px;
			height: 14px;
			margin: auto;
			margin-top: 4px;
			content: '';
			background: get-color(pink);
			transition: .2s;
		}
	}
	&_checkbox &-indicator {
		&::before {
			margin-top: 0;
			width: .4em;
			height: .7em;
			background: transparent;
			border-width: 0 3px 3px 0;
			border-style: solid;
			border-color: transparent get-color(pink) get-color(pink) transparent;
			transform: rotate(45deg);
		}
	}
	&_file &-indicator {
		flex-shrink: 1;
		flex-grow: 1;
		width: auto;
		height: 2.125em;
		padding: .25em .5em;
		margin-top: 0;
		margin-right: -1px;
		overflow: hidden;
		color: get-color(darkgray);
		text-overflow: ellipsis;
		white-space: nowrap;

		&::before {
			display: none;
		}
		&::after {
			content: attr(data-value);
		}
	}
	&_file &-text {
		@include button;
		font-size: .9em;
	}
	&-input:focus ~ &-indicator {
		border-color: get-color(pink);
	}
	&-input:checked ~ &-indicator::before {
		visibility: visible;
		opacity: 1;
	}

	&-text {
		font-weight: 500;
		font-size: 18px;
	}
}
