.b-testBoxForm {
	padding: 48px 15px;
	background-color: #dee2f1;

	@include breakpoint(xs, lg) {
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		border-radius: 15px;
	}

	&:not(:first-child) {
		margin-top: 32px;
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}

	&-title {
		margin-bottom: 28px;
		font-weight: 700;
		font-family: RobotoSlab;
		font-size: 30px;
		text-align: center;
	}
}
