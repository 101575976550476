.b-form {
	&_filter {
		padding: .8em 1em 0;
		background: get-color(lightgray);
	}

	&-row {
		@include row;

		&:last-child {
			margin-bottom: -20px;
		}
	}
	&-field {
		@include column(12);
		min-width: 180px;
		margin-bottom: 20px;

		@include breakpoint(lg) {
			@include column-width(auto);
		}

		&.m-error {
			select,
			textarea,
			input {
				border-color: get-color(danger);
			}
		}
	}
	&-label {
		display: block;
		margin-bottom: .1em;
		font-weight: bold;
		color: lighten(get-color(dark), 25%);

		&_required {
			&::after {
				color: get-color(danger);
				content: ' *';
			}
		}
	}
	&-error {
		margin-bottom: -20px;
		font-size: .875em;
		color: get-color(danger);

		&:empty {
			margin-bottom: 0;
		}
	}
	&-info {
		margin-top: .3em;
		font-size: .875em;
		color: get-color(darkgray);
	}

	select,
	textarea,
	input {
		width: 100%;
		@include input;
	}
	textarea {
		resize: vertical;
	}
	select {
		@include select;
	}

	&-fieldDesc {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-weight: 700;
	}

	&_subscribe {
		input {
			background-color: get-color(white);
		}
	}

	&_subscribe &-row {
		margin-right: 0;
		margin-left: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_subscribe &-field &-error {
		color: get-color(black);
	}

	&_subscribe &-field {
		@include column-width(12, 12, 0px);
		align-items: center;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;

		&.m-error {
			select,
			textarea,
			input {
				border-color: get-color(blue--dark);
			}
		}

		input {
			padding-right: 56px;
			border-radius: 40px;
		}

		&_action {
			@include column-width(none);
			display: flex;
			height: 48px;
			min-width: inherit;
			margin-left: -44px;

			.b-btn {
				align-self: center;
				width: 40px;
				height: 40px;
				font-weight: 700;
				background-color: get-color(pink);
				border-radius: 50%;
				padding: 0;
				text-align: center;
			}
		}
	}

	&_subscribeSat {
		.b-btn {
			width: 100%;
			font-weight: 700;
			font-family: RobotoSlab;

			@include breakpoint(lg) {
				padding-left: 18px;
				padding-right: 18px;
			}
		}
	}

	&_test {
		max-width: 456px;
		margin-left: auto;
		margin-right: auto;

		input {
			border-radius: 40px;
		}

		.b-btn {
			font-weight: 700;
			font-family: RobotoSlab;
		}
	}
}
