.b-pagination {
	margin-bottom: 3rem;
	text-align: center;

	&-prev,
	&-next {
		@include button(get-color(secondary));
		padding: .375em;
		font-size: 1.5em;
		vertical-align: middle;
		border-radius: 2em;

		&.m-disabled {
			opacity: .3;
			cursor: default;
		}

		&::before {
			content: '';
			position: relative;
			display: block;
		}
	}
	&-prev::before {
		@include sprite(arrow-left-white);
	}
	&-next::before {
		@include sprite(arrow-right-white);
	}
	&-list {
		@include reset-list;
		display: inline-block;
		margin: 0 1.25em;
		font-size: .875em;
		vertical-align: middle;

		li {
			display: inline-block;
		}
		a {
			@include button(get-color(light));
			width: 2.4em;
			padding-right: 0;
			padding-left: 0;
			border-radius: 2em;

			&.m-active {
				@include button(get-color(info));
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
	&-ellipsis {
		font-weight: bold;
		color: get-color(darkgray);
	}

	@include breakpoint(xs, sm) {
		&-prev,
		&-next {
			padding: .25em;
			font-size: 1em;
		}
		&-list {
			margin: 0;
		}
	}
}
