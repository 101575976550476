.b-cardStructureWrap {
	margin-bottom: 30px;

	@include breakpoint(lg) {
		@include row();
	}

	& > * {
		@include breakpoint(lg) {
			@include column(6);
		}

		&:not(:last-child) {
			@include breakpoint(xs, lg) {
				margin-bottom: 30px;
			}
		}
	}
}
