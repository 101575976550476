.b-socialLinks {
	@include row(10px);
	align-items: center;
	margin-top: -5px;

	&_end {
		justify-content: flex-end;
	}

	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		margin-top: 5px;
		margin-right: 5px;
		margin-left: 5px;
		border-radius: 5px;
		transition: opacity .3s;

		&:hover {
			opacity: .8;
		}
	}
}
