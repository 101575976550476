.b-head {
	position: relative;
	padding: 28px 0;
	// background: get-color(lightgray);

	@include breakpoint(xs, lg) {
		height: 106px;
	}

	&-container {
		@include container;
	}

	&-row {
		display: flex;
		margin-right: -15px;
		margin-left: -15px;
		align-items: center;
		justify-content: space-between;

		&:not(:last-child) {
			@include breakpoint(lg) {
				margin-bottom: 22px;
			}
		}

		& > * {
			margin-right: 15px;
			margin-left: 15px;
		}
	}

	&-logo {
		position: relative;
		min-height: 1px;
		max-width: 238px;

		@include breakpoint(xs, sm) {
			flex: 0 0 65%;
			width: 100%;
		}

		@include breakpoint(sm) {
			flex: 0 0 238px;
			width: 100%;
		}
	}

	&-lng {
		@include breakpoint(xs, lg) {
			position: absolute;
			top: 15px;
			right: 0;
		}

		a {
			font-size: 14px;
			font-weight: 700;
			color: #c9cacf;

			&:not(:last-child) {
				margin-right: 6px;
			}

			&.m-active {
				color: #799cdb;
			}
		}
	}

	&-menu {
		@include breakpoint(xs, lg) {
			position: absolute;
			top: 105px;
			left: 0;
			z-index: 1;
			margin: 0;
			width: 100%;
			height: calc(100vh - 106px);
			height: calc((var(--vh, 1vh) * 100) - 106px);
			padding: 40px 16px;
			overflow-y: auto;
			visibility: hidden;
			background: get-color(gray--lighter);
			opacity: 0;
			transition: opacity .3s;
			-webkit-overflow-scrolling: touch;
		}
	}

	&.m-active &-menu {
		@include breakpoint(xs, lg) {
			opacity: 1;
			visibility: visible;
		}
	}

	.b-humburger {
		@include breakpoint(lg) {
			display: none;
		}
	}

	@media print {
		display: none;
	}
}
