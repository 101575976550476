.l-main {
	@include container-row;
	flex-grow: 1;
	// margin-top: 16px;
	// margin-bottom: 3rem;

	&-head {
		@include column(12);
	}
	&-sidebar {
		@include column(3);

		&_left {
			order: 0;
		}
		&_right {
			order: 2;
		}

		@include breakpoint(xs, md) {
			@include column-width(12);
			order: 0;
			margin-bottom: 3rem;
		}
		@include breakpoint(md, lg) {
			@include column-width(4);
		}
	}
	&-content {
		@include column(auto);
		order: 1;
	}

	&-title {
		font-weight: 700;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			margin-bottom: 16px;
			margin-top: 32px;
			font-size: 36px;
			text-align: center;
		}

		@include breakpoint(lg) {
			margin-top: 32px;
			margin-bottom: 20px;
			font-size: 36px;
		}
	}

	&-desc {
		@include breakpoint(lg) {
			max-width: column-width(10, 12, 30px);
			margin-left: auto;
			margin-right: auto;
		}
	}
}
