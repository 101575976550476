.b-card {
	display: flex;
	flex-direction: column;
	background: get-color(lightgray);

	&-head {
		flex-shrink: 0;
		img {
			width: 100%;

			@include breakpoint(xs, sm) {
				width: 100%;
			}
		}
	}
	&-body {
		flex-grow: 1;
		margin: .5em 1em;
	}
	&-foot {
		@include row(1px);

		> * {
			@include column(auto, 1px);
		}
	}
}
