.l-row {
	@include row;

	&_ruler {
		> * {
			font-size: .8rem;
			text-align: center;
			background: lightblue;

			&:hover::before {
				position: absolute;
				top: -100vh;
				right: 0;
				bottom: -100vh;
				left: 0;
				z-index: 1000;
				content: '';
				border-color: red;
				border-style: solid;
				border-width: 0 1px;
				pointer-events: none;
			}
		}
	}

	&-col {
		@include column(auto);
	}

	@for $size from 1 through $grid-columns {
		&-col#{$size} {
			@extend .l-row-col;
			@include column-width($size);
		}
	}
}
