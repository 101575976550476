.b-mediaTest {
	display: flex;
	background-color: #dee2f1;
	border-radius: 15px;

	@include breakpoint(sm) {
		align-items: center;
	}


	@include breakpoint(xs, lg) {
		padding: 16px;
	}

	@include breakpoint(lg) {
		padding: 32px;
	}

	&-icon {
		margin-right: 16px;
		width: 100%;

		@include breakpoint(xs, lg) {
			flex: 0 0 60px;
			max-width: 60px;
		}

		@include breakpoint(lg) {
			flex: 0 0 25%;
			max-width: 25%;
		}

		.b-icon {
			@include breakpoint(xs, lg) {
				transform: scale(.6);
				transform-origin: top left;
			}
		}
	}

	&-text {
		width: 100%;

		@include breakpoint(xs, lg) {
			flex: 0 0 calc(100% - 76px);
			max-width: calc(100% - 76px);
		}

		@include breakpoint(lg) {
			flex: 0 0 calc(75% - 16px);
			max-width: calc(75% - 16px);
		}
	}

	&-accent {
		color: get-color(blue--darker);
		font-size: 18px;
		font-weight: 700;
	}

	&-num {
		font-size: 24px;
	}
}
