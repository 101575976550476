.b-breadcrumbs {
	font-weight: 300;
	margin-bottom: 16px;

	&-list {
		@include reset-list;

		li {
			display: inline-block;
			color: #666666;

			& + li::before {
				margin-right: .3ex;
				color: #666666;
				content: '-';
			}

			a {
				color: get-color(blue);

				&:hover {
					color: rgba($color: get-color(blue), $alpha: .8);
				}
			}
		}
	}

	@media print {
		display: none;
	}
}
