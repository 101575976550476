.b-btn {
	@include button;

	&_block {
		display: block;
	}
	&_lg {
		font-size: 1.25em;
	}
	&_sm {
		font-size: .875em;
	}

	@each $color, $value in $colors {
		&_#{$color} {
			color: color-yiq($value);
			background: $value;

			&:hover {
				color: color-yiq(darken($value, 7.5%));
				background: darken($value, 7.5%);
			}
		}
	}

	&_b0 {
		border-radius: 0;
	}

	&_whiteColor {
		color: get-color(white);

		&:hover {
			color: get-color(white);
		}
	}

	&_outlineBlue {
		color: get-color(blue);
		background-color: transparent;
		border-color: get-color(blue);

		&:hover {
			background-color: get-color(blue);
			color: get-color(white);
			border-color: get-color(blue);
		}

		&[disabled] {
			cursor: default;
			pointer-events: none;
			color: #666666;
			border-color: #666666;
		}
	}

	&_outlineWhite {
		color: get-color(white);
		background-color: transparent;
		border-color: get-color(white);

		&:hover {
			background-color: get-color(white);
			color: get-color(black);
			border-color: get-color(white);
		}
	}

	&_outlineGrayLight {
		color: #666666;
		background-color: transparent;
		border-color: #c9cacf;

		&:hover {
			background-color :#c9cacf;
			color: #666666;
			border-color: #c9cacf;
		}
	}
}
