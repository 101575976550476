.b-modal {
	$easingFunction: cubic-bezier(.5, 0, .3, 1);

	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	display: flex;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	visibility: hidden;
	background: rgba(0, 0, 0, .6);
	opacity: 0;
	transition: opacity .2s linear;

	@include breakpoint(md) {
		align-items: center;
	}

	&.m-show {
		visibility: visible;
		opacity: 1;
	}

	&-container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		padding-right: 16px;
		padding-left: 16px;
		margin-right: auto;
		margin-left: auto;

		@include breakpoint(lg) {
			max-width: 1472px;
		}
	}

	&-wrap {
		position: relative;
		width: 100%;
		pointer-events: none;

		@include breakpoint(sm) {
			margin: auto 0;
		}

		@include breakpoint(md) {
			padding: 24px 0 24px;
			margin: auto;
		}

		@include breakpoint(lg) {
			max-width: 920px;
			padding: 64px 56px;
		}
	}

	&_p0 &-dialog {
		padding: 0;
	}

	&-dialog {
		position: relative;
		margin: auto;
		pointer-events: all;
		background: get-color(white);
		transition: .2s linear;

		@include breakpoint(xs, sm) {
			min-height: 100%;
		}

		@include breakpoint(xs, md) {
			padding: 30px 16px;
		}

		@include breakpoint(md) {
			padding: 30px;
		}

		@include breakpoint(lg) {
			border-radius: 15px;
		}


		&.m-loading {
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				pointer-events: none;
				content: '';
				background: rgba(get-color(dark), .5);
			}
		}
	}

	&-close {
		position: absolute;
		top: 16px;
		right: 16px;
		z-index: 5;
		pointer-events: all;
		cursor: pointer;
		transition: filter .3s;
		@include sprite('x-black');

		@include breakpoint(sm) {
			top: 24px;
			right: 24px;
		}

		@include breakpoint(md) {
			top: 48px;
		}

		@include breakpoint(lg) {
			top: 32px;
			right: 24px;
			filter: invert(1);
		}

		&:hover {
			@include breakpoint(lg) {
				opacity: .8;
			}
		}
	}

	&-head {
		padding: 0 0 26px;
	}

	&-title {
		font-weight: 700;

		@include breakpoint(xs, lg) {
			margin: 0 0 24px;
			font-size: 32px;
		}

		@include breakpoint(lg) {
			margin: 0 0 24px;
			font-size: 48px;
		}
	}
}
