.b-mediaTestList {
	@include breakpoint(lg) {
		@include row();
		margin-bottom: -30px;
	}

	&:not(:last-child) {
		@include breakpoint(xs, lg) {
			margin-bottom: 32px;
		}

		@include breakpoint(lg) {
			margin-bottom: 0;
		}
	}

	& > * {
		@include breakpoint(lg) {
			@include column(6);
			min-height: 220px;
			margin-bottom: 30px;
		}

		&:not(:last-child) {
			@include breakpoint(xs, lg) {
				margin-bottom: 15px;
			}
		}
	}
}
