$text-types: (
	text,
	password,
	date,
	datetime,
	datetime-local,
	month,
	week,
	email,
	number,
	search,
	tel,
	time,
	url,
	color,
) !default;

$button-types: (
	button,
	reset,
	submit,
) !default;

@function types($types: ()) {
	$return: ();

	@each $type in $types {
		$return: append($return, unquote('[type=\'#{$type}\']'), comma);
	}

	@return $return;
}

@mixin clearfix {
	&::before,
	&::after {
		display: table;
		content: '';
	}
	&::after {
		clear: both;
	}
}

@mixin font-face($alias, $font-path, $weight: normal, $style: normal) {
	@font-face {
		font-family: $alias;
		font-weight: $weight;
		font-style: $style;
		src: url('#{$font-path}.woff') format('woff'), url('#{$font-path}.woff2') format('woff2');
		font-display: swap;
	}
}

@mixin icon($initial-bg, $hover-bg) {
	&::before {
		@include sprite($initial-bg);
	}

	&::after {
		@include sprite($hover-bg);
	}
}

@mixin ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin hide-scrollbar {
	-ms-overflow-style: none;  // IE 10+
	scrollbar-width: none;  // Firefox

	&::-webkit-scrollbar {
		display: none;  // Safari and Chrome
	}
}