.b-cardList {
	@include row();
	margin-bottom: calc(3rem - #{$grid-gutter});

	> * {
		@include column(12);
		margin-bottom: $grid-gutter;

		@include breakpoint(sm) {
			@include column-width(6);
		}
		@include breakpoint(lg) {
			@include column-width(4);
		}
	}
}
