.b-infoBox {

	@include breakpoint(lg) {
		background-color: get-color(white);
		border-radius: 15px;
		color: initial;
	}

	&-row {
		@include breakpoint(lg) {
			@include row();
			flex-direction: row-reverse;
		}

		& > * {
			@include breakpoint(lg) {
				@include column(6);
			}
		}
	}

	&-title {
		margin-bottom: 20px;
		font-weight: 700;
		font-size: 30px;
		font-family: RobotoSlab;
	}

	&-text {
		margin-bottom: 24px;
	}

	&-img {
		@include breakpoint(xs, lg) {
			margin: -30px -15px 24px;
		}

		.g-ratio {
			@include breakpoint(lg) {
				margin-top: -12px;
				margin-bottom: -12px;
				margin-right: 36px;
			}
		}

		img {
			@include breakpoint(lg) {
				border-radius: 5px;
			}
		}
	}

	&-content {
		@include breakpoint(xs, lg) {
			text-align: center;
		}

		@include breakpoint(lg) {
			padding: 40px;
		}
	}
}
