.b-benefitList {
	@include row();
	margin-bottom: -16px;

	&:not(:last-child) {
		margin-bottom: 32px;
	}

	& > * {
		@include column(6);
		margin-bottom: 16px;
		text-align: center;
	}

	&-title {
		margin-top: 12px;

		@include breakpoint(xs, sm) {
			font-size: 12px;
		}

		span {
			font-weight: 700;
			font-size: 24px;
			color: get-color(blue--darker);

			@include breakpoint(xs, sm) {
				font-size: 18px;
			}
		}
	}
}
