.b-cardStepList {
	@include row();
	margin-bottom: -15px;

	@include breakpoint(lg) {
		height: 100%;
		margin-bottom: -30px;
	}

	& > * {
		@include column(6);
		margin-bottom: 15px;

		@include breakpoint(xs, lg) {
			min-height: 76px;
		}

		@include breakpoint(lg) {
			min-height: calc(50% - 15px);
			margin-bottom: 30px;
		}
	}
}
