.g-hidden {
	&_md {
		@include breakpoint(xs, md) {
			display: none;
		}
	}

	&_xsLg {
		@include breakpoint(xs, lg) {
			display: none;
		}
	}

	&_lg {
		@include breakpoint(lg) {
			display: none;
		}
	}
}
