.g-spacer {
	@include breakpoint(xs, lg) {
		margin-bottom: 56px;
	}

	@include breakpoint(lg) {
		margin-bottom: 96px;
	}

	&_sm {
		@include breakpoint(xs, lg) {
			margin-bottom: 24px;
		}

		@include breakpoint(lg) {
			margin-bottom: 48px;
		}
	}
}
