.swiper-container {
	opacity: 0;
	transition: opacity .3s;

	&.swiper-container-initialized {
		opacity: 1;
	}
}

@mixin swiper-slide {
	position: relative;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	transition-property: transform;
}
