.b-subscribeBox {
	background-color: #c20000;
	color: get-color(white);

	@include breakpoint(xs, lg) {
		padding: 15px 15px 24px;
		margin: 0 -15px;
	}

	@include breakpoint(lg) {
		padding: 15px;
		padding-right: 32px;
		border-radius: 15px;
	}

	&-row {
		@include row();
		align-items: center;

		& > * {
			margin: 0 15px;
		}
	}

	&-title {
		font-weight: 700;
		font-family: RobotoSlab;

		@include breakpoint(xs, lg) {
			font-size: 24px;
			max-width: calc(100% - 140px);
			flex: 0 0 calc(100% - 140px);
		}

		@include breakpoint(lg) {
			font-size: 30px;
		}
	}

	&-form {
		@include column(12);

		@include breakpoint(xs, lg) {
			margin-top: 10px;
		}

		@include breakpoint(md) {
			@include column-width(auto, 12, 30px);
		}
	}

	&-img {
		@include breakpoint(xs, lg) {
		}

		// img {
			@include breakpoint(xs, lg) {
				max-width: 124px;
				padding-left: 15px;
				margin: -30px 0 0 -15px;
			}

			@include breakpoint(lg) {
				margin: -36px 0 -36px -30px;
			}
		// }
	}
}
