.b-weekDays {
	@include row(0px);

	&-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 24px;
		font-size: 14px;
		font-weight: 700;
		background: #233a63;
		color: get-color(white);

		&.m-active {
			background: #799cdb;
		}
	}
}
