$breakpoints: map-get($theme, breakpoints);

$container-max: (
	lg: 1170px,
);

$grid-columns: 12;
$grid-gutter: 30px;

$colors: map-get($theme, colors);
