.b-accordion {
	@include reset-list;
	border-top: 1px solid get-color(darkgray);

	&-item {
		border-bottom: 1px solid get-color(darkgray);
	}

	&-header {
		position: relative;
		cursor: pointer;
		transition: color .3s cubic-bezier(.5,0,.3,1);
		padding: 8px 24px 8px 0;

		&::after,
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 0;
			background-color: get-color(darkgray);
			transition: background-color .3s cubic-bezier(.5,0,.3,1);
		}

		&::before {
			height: 1px;
			width: 9px
		}

		&::after {
			width: 1px;
			height: 9px;
			margin-top: -4px;
			margin-right: 4px;
			transition: transform .3s cubic-bezier(.5,0,.3,1), background-color .3s cubic-bezier(.5,0,.3,1);
		}
	}

	&-item.m-open &-header::after {
		transform: scaleY(0);
	}

	&-body {
		display: none;
		padding: 8px 0 16px;
	}

	&-content {
		opacity: 0;
		transition: opacity .45s cubic-bezier(.5,0,.3,1);
	}

	&-item.m-open &-content {
		opacity: 1;
		transition-delay: .2s;
	}
}
