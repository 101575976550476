.b-mediaSlider {
	position: relative;

	&-container {
		@include breakpoint(xs, lg) {
			overflow: visible;
		}
	}

	&-prev,
	&-next {
		position: absolute;
		top: 50%;

		@include breakpoint(xs, lg) {
			display: none;
		}
	}

	&-prev {
		right: 100%;
		margin-right: 14px;
		margin-top: -26px;
		@include sprite(arrow-lx-left-blue, true);
	}

	&-next {
		left: 100%;
		margin-left: 14px;
		margin-top: -26px;
		@include sprite(arrow-lx-right-blue, true);
	}

	.b-media {
		@include swiper-slide;
		height: auto;
	}
}
